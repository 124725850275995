import { CURRENCY_COOKIE_NAME, DEFAULT_CURRENCY, findCurrencyByCode } from '~/config/currencies';

export const useCurrencyStore = defineStore('currencyStore', () => {
  const runtimeConfig = useRuntimeConfig();
  const envData = runtimeConfig?.public;

  // ### COOKIE
  const currency = useCookie(CURRENCY_COOKIE_NAME, {
    default: () => DEFAULT_CURRENCY?.code,
    domain: envData?.COOKIE_DOMAIN,
    path: '/',
    sameSite: 'Strict',
    secure: true,
    watch: true,
  });

  // ### STATE
  const showCurrencyPicker = ref(false);

  // ### COMPUTED
  const getCurrentCurrency = computed(() => {
    return findCurrencyByCode(currency.value) || DEFAULT_CURRENCY;
  });

  const isCurrencyPickerOpen = computed(() => {
    return showCurrencyPicker?.value;
  });

  // ### ACTIONS
  function setCurrency(newCurrency) {
    const currencyToSet = findCurrencyByCode(newCurrency) || DEFAULT_CURRENCY;
    currency.value = currencyToSet.code;
  }

  function toggleCurrencyPicker(isVisible) {
    showCurrencyPicker.value = isVisible;
  }

  return {
    showCurrencyPicker,
    getCurrentCurrency,
    isCurrencyPickerOpen,
    setCurrency,
    toggleCurrencyPicker,
  };
});
